
  import { defineComponent, onMounted, reactive } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import PalletDetailCode from './PalletDetailCode.vue';
  export default defineComponent({
    components: {
      PalletDetailCode
    },
    props: {
      propsColor: () => {},
      propsStatus: Boolean
    },
    setup(props) {
      const store = useStore(key);
      const data = reactive({
        isShowDialog: false
      })
      const closeDetails = (): void => {
        store.dispatch('toggleShowDetails', false)
        setTimeout((): void => {
          store.dispatch('updateCurrentColor', {
            id: null,
            category: null,
            tags: null,
            code: null
          })
        }, 100);
      };
      onMounted(() => {
        setTimeout((): void => {
          data.isShowDialog = props.propsStatus;
        }, 400);
      }); 
      return {
        store,
        data,
        closeDetails
      }
    },
  });
