
  import { defineComponent, reactive, watchEffect } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import { useRoute } from 'vue-router';
  import PalletItem from './PalletItem.vue';

  interface colorItemInterface {
    id: number | null,
    category: string | null,
    tags: string[] | null,
    code: string[] | null,
  }

  interface dataInterface {
    currentShowItems: colorItemInterface[],
    total: number,
    loadParCount: number,
    offset: number,
    currentPage: string | string[]
  }

  export default defineComponent({
    components: {
      PalletItem
    },
    props: {
      propsColors: {
        type: () => [],
        default: []
      }
    },
    setup(props) {
      const store = useStore(key);
      const route = useRoute();
      const data:dataInterface = reactive({
        currentShowItems: [],
        total: 0,
        loadParCount: 12,
        offset: 0,
        currentPage: ''
      });
      const init = () => {
        data.offset = 0;
        data.currentShowItems = [];
        setTimeout(() => {
          data.currentShowItems = props.propsColors.slice(0, data.loadParCount);
          data.total = props.propsColors.length;
        }, 100)
      };
      const load = () => {
        data.offset += data.loadParCount;
        data.currentShowItems = data.currentShowItems.concat(props.propsColors.slice(data.offset, data.offset + data.loadParCount));
      };
      watchEffect(() => {
        data.currentPage = route.params.term;
        init();
      });
      return {
        store,
        data,
        route,
        watchEffect,
        init,
        load
      }
    },
  });
