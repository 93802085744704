
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import PalletDetailBackground from './PalletDetailBackground.vue';
  import PalletDetailDialog from './PalletDetailDialog.vue';
  export default defineComponent({
    components: {
      PalletDetailBackground,
      PalletDetailDialog
    },
    setup() {
      const store = useStore(key);
      return {
        store
      }
    },
  });
