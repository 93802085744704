import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "slide",
    mode: "out-in",
    tag: "div",
    class: "__background u-flex-center-stretch",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDetails()))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propsColor.code, (hex, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: hex,
          class: "__panel u-flex-shrink-1",
          style: _normalizeStyle({background: hex, transitionDelay: `calc(${(index + 1) * 0.3}s + 0.1s)`})
        }, null, 4 /* STYLE */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}