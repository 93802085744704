
  import { defineComponent, computed } from 'vue';

  export default defineComponent({
    setup() {
      const currentYear = computed((): number => {
        const currentTime = new Date();
        return currentTime.getFullYear()
      });
      return {
        currentYear
      }
    },
  })
