
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  import PalletContainer from './PalletContainer.vue';

  interface colorItemInterface {
    id: number | null,
    category: string | null,
    tags: string[] | null,
    code: string[] | null,
  }

  export default defineComponent({
    components:  {
      PalletContainer
    },
    setup() {
      const store = useStore(key);
      store.dispatch('fetchColorsQuint');
      const randomSorted = computed(():colorItemInterface[] => {
        const colorsList = store.getters.getColorsQuint;
        for (let index = colorsList.length - 1; index >= 0; index--) {
          const randomNumber = Math.floor(Math.random() * (index + 1));
          [colorsList[index], colorsList[randomNumber]]  = [colorsList[randomNumber], colorsList[index]];
        }
        return colorsList;
      });
      return {
        store,
        randomSorted
      }
    }
  });
