
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { key } from '../store';
  export default defineComponent({
    setup() {
      const store = useStore(key);
      const router = useRouter();
      store.dispatch('fetchTagList');
      const routerLink = (name: string): void => {
        router.push(`/tags/${name}`);
      }
      return {
        store,
        router,
        routerLink
      }
    },
  });
