
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  export default defineComponent({
    props: {
      propsColor: () => {},
      propsStatus: Boolean
    },
    setup(props) {
      const store = useStore(key);
      const closeDetails = (): void => {
        store.dispatch('toggleShowDetails', false)
        setTimeout((): void => {
          store.dispatch('updateCurrentColor', {
            id: null,
            category: null,
            tags: null,
            code: null
          })
        }, 100);
      };
      return {
        store,
        closeDetails
      }
    },
  });
