import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "__tags-group" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (count) => {
      return _createElementVNode("ul", {
        key: count,
        class: "__tags u-flex-start-center"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getters.getTagList, (tag) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tag.id,
            class: "__tag u-capital-case u-bold u-italic",
            onClick: ($event: any) => (_ctx.routerLink(tag.name))
          }, _toDisplayString(tag.name), 9 /* TEXT, PROPS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}