
  import { defineComponent } from 'vue';
  export default defineComponent({
    props: {
      propsCode: String
    },
    setup() {
      const copyCode = (hex: string): void => {
        const listener = (event: any) => {
          event.clipboardData.setData('text/plain' , hex);
          event.preventDefault();
          document.removeEventListener('copy', listener);
        }
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        alert(`【${hex}】HEX code copied!!`)
      };
      const codeTextColor = (hex: string): string => {
        const rgb = {
          r: parseInt(hex.slice(1,3), 16),
          g: parseInt(hex.slice(3,5), 16),
          b: parseInt(hex.slice(5,7), 16)
        };
        return (rgb.r + rgb.g + rgb.b) < (256 * 3) / 2 ? '#FFF' : '#000';
      }
      return {
        copyCode,
        codeTextColor
      }
    },
  });
