import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dad603ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-color-detail-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pallet_detail_background = _resolveComponent("pallet-detail-background")!
  const _component_pallet_detail_dialog = _resolveComponent("pallet-detail-dialog")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.store.getters.getIsShowDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_pallet_detail_background, {
              "props-color": _ctx.store.getters.getCurrentColor,
              "props-status": _ctx.store.getters.getIsShowDetails
            }, null, 8 /* PROPS */, ["props-color", "props-status"]),
            _createVNode(_component_pallet_detail_dialog, {
              "props-color": _ctx.store.getters.getCurrentColor,
              "props-status": _ctx.store.getters.getIsShowDetails
            }, null, 8 /* PROPS */, ["props-color", "props-status"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}