'use strict';

// stylesheet import
import '../sass/main.sass';

import { createApp } from 'vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass';
import { IsLegacyIE } from './modules/checking-legacy-ie';
import { FindOS } from './modules/checking-os';
import { FindBrowser } from './modules/checking-browser';
import VueTagsScroller from './vue/TagsScroller.vue';
import VueTagsSelector from './vue/TagsSelector.vue';
import VuePalletContainer from './vue/PalletContainer.vue';
import VuePalletItem from './vue/PalletItem.vue';
import VuePalletDetail from './vue/PalletDetail.vue';
import VueCopyright from './vue/Copyright.vue';
import { store, key } from './store';
import { router } from './route';

// Vue.js
const app = createApp({});
app.component('tags-scroller', VueTagsScroller)
  .component('tags-selector', VueTagsSelector)
  .component('pallet-container', VuePalletContainer)
  .component('pallet-item', VuePalletItem)
  .component('pallet-detail', VuePalletDetail)
  .component('copyright', VueCopyright)
  .use(store, key)
  .use(router)
  .mount('#app');

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();