import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import axios, { AxiosResponse, AxiosError } from 'axios';

interface currentColorInterface {
  id: number | null,
  category: string | null,
  tags: string[] | null,
  code: string[] | null,
}

export interface State {
  isShowDetails: boolean,
  colorsTriple: currentColorInterface[],
  colorsQuad: currentColorInterface[],
  colorsQuint: currentColorInterface[],
  tagList: [],
  currentColor: currentColorInterface  | null
}
export const key: InjectionKey<Store<State>> = Symbol();
export const store = createStore<State>({
  state: {
    isShowDetails: false,
    colorsTriple: [],
    colorsQuad: [],
    colorsQuint: [],
    tagList: [],
    currentColor: {
      id: null,
      category: null,
      tags: null,
      code: null
    }
  },
  mutations: {
    setShowDetails: (state, payload: boolean) => {
      state.isShowDetails = payload;
    },
    setColorsTriple: (state, payload: currentColorInterface[]) => {
      state.colorsTriple = payload;
    },
    setColorsQuad: (state, payload: currentColorInterface[]) => {
      state.colorsQuad = payload;
    },
    setColorsQuint: (state, payload: currentColorInterface[]) => {
      state.colorsQuint = payload;
    },
    setTagList: (state, payload: []) => {
      payload.forEach((item) => {
        state.tagList.push(item);
      });
    },
    setCurrentColor: (state, payload: currentColorInterface) => {
      state.currentColor = payload;
    }
  },
  actions: {
    toggleShowDetails: ({ commit }, status: boolean) => {
      commit('setShowDetails', status);
    },
    fetchColorsTriple: ({ commit }) => {
      axios.get('/assets/data/triple.json')
        .then((response:  AxiosResponse) => {
          commit('setColorsTriple', response.data);
        })
        .catch((error: AxiosError) => error);
    },
    fetchColorsQuad: ({ commit }) => {
      axios.get('/assets/data/quad.json')
        .then((response:  AxiosResponse) => {
          commit('setColorsQuad', response.data);
        })
        .catch((error: AxiosError) => error);
    },
    fetchColorsQuint: ({ commit }) => {
      axios.get('/assets/data/quint.json')
        .then((response:  AxiosResponse) => {
          commit('setColorsQuint', response.data);
        })
        .catch((error: AxiosError) => error);
    },
    fetchTagList: ({ commit }) => {
      axios.get('/assets/data/tags.json')
        .then((response:  AxiosResponse) => {
          commit('setTagList', response.data);
        })
        .catch((error: AxiosError) => error);
    },
    updateCurrentColor: ({ commit }, data: currentColorInterface) => {
      commit('setCurrentColor', data);
    }
  },
  getters: {
    getIsShowDetails: (state) => state.isShowDetails,
    getColorsTriple: (state) => state.colorsTriple,
    getColorsQuad: (state) => state.colorsQuad,
    getColorsQuint: (state) => state.colorsQuint,
    getColorsAll: (state, getters) => [...getters.getColorsTriple, ...getters.getColorsQuad, ...getters.getColorsQuint],
    getTagList: (state) => state.tagList,
    getCurrentColor: (state) => state.currentColor
  }
});
export const useStore = (): any => {
  return baseUseStore(key);
};