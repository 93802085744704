
  import { defineComponent, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { key } from '../store';

  interface dataInterface {
    isShownSelector: boolean,
  }

  export default defineComponent({
    setup() {
      const store = useStore(key);
      const router = useRouter();
      const data:dataInterface = reactive({
        isShownSelector: false
      });
      const toggleShowSelector = (): void => {
        data.isShownSelector = !data.isShownSelector;
      };
      const routerLink = (event: any): void => {
        router.push(`/tags/${event.target.value}`);
        data.isShownSelector = false;
      };
      return {
        store,
        router,
        data,
        toggleShowSelector,
        routerLink
      }
    },
  });
