import { createRouter, createWebHistory } from 'vue-router';
import Home from './vue/Home.vue';
import Triple from './vue/Triple.vue';
import Quad from './vue/Quad.vue';
import Quint from './vue/Quint.vue';
import Tags from './vue/Tags.vue';
import Error404 from './vue/404.vue';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/triple',
      name: 'Triple',
      component: Triple
    },
    {
      path: '/quad',
      name: 'Quad',
      component: Quad
    },
    {
      path: '/quint',
      name: 'Quint',
      component: Quint
    },
    {
      path: '/tags/:term',
      name: 'Tags',
      component: Tags
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Page Not Found',
      component: Error404
    },
  ]
});