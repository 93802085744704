
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import { key } from '../store';
  export default defineComponent({
    props: {
      propsColor: () => {}
    },
    setup(props) {
      const store = useStore(key)
      const showDetails = (): void => {
        store.dispatch('toggleShowDetails', true);
        setTimeout((): void => {
          store.dispatch('updateCurrentColor', props.propsColor);
        }, 200)
      };
      return {
        store,
        showDetails
      }
    },
  })
