
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { key } from '../store';
  import PalletContainer from './PalletContainer.vue';

  interface colorItemInterface {
    id: number,
    category: string,
    tags: string[],
    code: string[],
  }
  
  export default defineComponent({
    components:  {
      PalletContainer
    },
    setup() {
      const store = useStore(key);
      const route = useRoute();
      store.dispatch('fetchColorsTriple');
      store.dispatch('fetchColorsQuad');
      store.dispatch('fetchColorsQuint');
      const filtered = computed(() => {
        const colorsList = store.getters.getColorsAll.filter((item: colorItemInterface) => {
          return item.tags.find((keyword: string) => {
            return keyword === route.params.term;
          });
        });
        for (let index = colorsList.length - 1; index >= 0; index--) {
          const randomNumber = Math.floor(Math.random() * (index + 1));
          [colorsList[index], colorsList[randomNumber]]  = [colorsList[randomNumber], colorsList[index]];
        }
        return colorsList;
      });
      return {
        store,
        route,
        filtered
      }
    }
  });
